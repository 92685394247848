import React from "react"

import Testimonial from "./testimonial"
import { useStaticQuery, graphql } from "gatsby" // to query for image data

const T = props => {
  console.log(props)
  return (
    <Testimonial
      className={props.className}
      by="Mike Rowe-Eyres, Airspace Aviation Solutions"
      text="Simplicity, flexibility & performance enhancing - Working with Cargo-Planner has made Airspace Aviation more efficient on planning flights due to the simplicity involved. The flexibility of the Cargo-Planner team working alongside us to create a planner from a vision to a reality is superb and we cannot thank them enough."
    ></Testimonial>
  )
}

export default T
