import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import FeaturesWithImages from "../components/features_with_images"
import Benefits from "../components/benefits"
import SignupBanner from "../components/signupBanner"
import { graphql } from "gatsby" // to query for image data
import RockItTestimonial from "../components/testimonials/joe_rockit"
import AASTestimonal from "../components/testimonials/mike_aas"

const CompositionsPage = ({ data }) => (
  <Layout>
    <SEO title="Compositions" />
    <Hero image={data.hero} title="Compositions" className="bg-white">
      Use combination of ULDs, trailers and containers to create interactive
      load plans for entire <b>aircrafts</b>, <b>road trains</b> or even{" "}
      <b>warehouses</b>.
    </Hero>
    <RockItTestimonial className="bg-primary text-white" />

    <FeaturesWithImages
      features={[
        {
          title: "Plan everything",
          image: data.B767300F,
          content: (
            <span>
              Want to plan all containers in an aircraft? Cargo-Planner now
              supports creation of compositions that can be used to quickly plan
              entire aircrafts, road trains, warehouses and much more.
            </span>
          ),
        },
        {
          title: "Use our library",
          image: data.library,
          content: (
            <span>
              No need to wait, get started by using common Boeing and Airbus
              aircrafts from our growing library.
            </span>
          ),
        },
        {
          title: "Customize to your needs",
          image: data.builder,
          content: (
            <span>
              Cant find a suitable composition in our library? No worries, you
              can always build your own to make sure that all your requirements
              are fulfilled. If you need any help we will gladly assist you.
            </span>
          ),
        },
      ]}
    ></FeaturesWithImages>
    <AASTestimonal />
    <Benefits></Benefits>
    <SignupBanner></SignupBanner>
  </Layout>
)

export const squareImage = graphql`
  fragment squareImage on File {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
`
export const query = graphql`
  {
    hero: file(relativePath: { eq: "aircrafts/A330-200-2PF.png" }) {
      base
      childImageSharp {
        gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
      }
    }
    B767300F: file(relativePath: { eq: "compositions/B767-300F.png" }) {
      base
      childImageSharp {
        gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
      }
    }
    builder: file(relativePath: { eq: "compositions/builder.png" }) {
      base
      childImageSharp {
        gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
      }
    }
    library: file(relativePath: { eq: "compositions/library.png" }) {
      base
      childImageSharp {
        gatsbyImageData(height: 460, quality: 100, layout: CONSTRAINED)
      }
    }
  }
`

export default CompositionsPage
