import React from "react"

import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import CalendlyButton from "./calendly"
import { event } from "../util"

const Hero = props => {
  return (
    <div className="container sm:min-h-screen px-12 mx-auto flex flex-col justify-center">
      <div className="pb-[10%]">
        <div className="w-full flex items-center flex flex-wrap flex-col-reverse lg:flex-row sm:flex-col">
          <div
            className="flex flex-col w-full lg:w-3/5 justify-center items-center md:items-start text-center md:text-left"
            style={{ zIndex: 1 }}
          >
            <h1 className="my-4 text-4xl md:text-6xl font-black mb-6">
              {props.title}
            </h1>
            {props.subtitle && (
              <h2 className="my-4 text-2xl md:text-4xl  font-bold leading-tight">
                {props.subtitle}
              </h2>
            )}
            {props.body && (
              <p className="leading-normal text-xl md:text-2xl mb-8">
                {props.body}
              </p>
            )}
            {props.children && (
              <p className="leading-normal text-xl md:text-2xl mb-8">
                {props.children}
              </p>
            )}
            {!props.noSignup && (
              <div className="flex flex-wrap gap-1">
                <Link
                  to="/register/"
                  style={{ textAlign: "center" }}
                  className="w-80 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110 uppercase block bg-primary text-white font-bold rounded-md my-6 py-4 px-8 shadow-2xl mr-4"
                >
                  Start a free trial
                </Link>{" "}
                <CalendlyButton />
              </div>
            )}
          </div>
          <div className="w-full lg:w-2/5 text-center content-center py-0 md:py-12 pr-6">
            {!!props.video && (
              <video
                autoPlay
                loop
                muted
                disablePictureInPicture
                playsInline
                style={{
                  width: "150%",
                  maxWidth: "150%",
                  marginLeft: "-25%",
                  marginTop: "-10%",
                  zIndex: -1,
                }}
              >
                <source src={props.video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}

            {!props.video && !!props.image && (
              <GatsbyImage
                image={props.image.childImageSharp.gatsbyImageData}
                alt={props.image.base.replace(/-/g, " ").split(".")[0]}
              />
            )}
          </div>
        </div>
        <div className="2xl:justify-between 2xl:max-w-full">
          <div className="flex flex-wrap justify-between sm:justify-center lg:justify-between items-center">
            {props.customers &&
              props.customers.map((image, i) => (
                <div className="mb-2 md:mx-5 w-32" key={i}>
                  <GatsbyImage
                    image={image.childImageSharp.gatsbyImageData}
                    key={i}
                    alt={image.base.split(".")[0]}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
