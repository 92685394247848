import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons"
import { GatsbyImage } from "gatsby-plugin-image"

const Testimonial = props => {
  return (
    <div className={props.className}>
      <div className="py-6 container px-4 lg:px-24 xl:px-48 mx-auto flex flex-wrap text-center items-center ">
        <blockquote className="relative p-4 my-4 text-xl italic ">
          <p className="mb-4 text-left font-serif text-2xl">
            <FontAwesomeIcon
              icon={faQuoteLeft}
              className="text-gray-300 mr-4 text-6xl float-left "
            />
            {props.text}
            {/* <FontAwesomeIcon icon={faQuoteRight} className="text-primary ml-4" /> */}
          </p>

          <cite className="flex items-center justify-center">
            {props.image && (
              <GatsbyImage
                image={props.image.file.childImageSharp.gatsbyImageData}
                alt="j"
                className="w-12 mr-4 rounded-full bg-neutral-500"
              />
            )}
            <div className="flex flex-col items-start text-center">
              <span className="mb-1 text-lg italic font-bold">{props.by}</span>
            </div>
          </cite>
        </blockquote>
      </div>
    </div>
  )
}

export default Testimonial
