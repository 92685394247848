import React from "react"

import {
  PresentationChartLineIcon,
  ClockIcon,
  BriefcaseIcon,
  ArchiveIcon,
} from "@heroicons/react/outline"

const Benefits = ({}) => {
  return (
    <div className="py-12 lg:px-24 xl:px-48" id="why">
      <h2 className="text-center text-4xl text-black font-semibold tracking-wider">
        Benefits
      </h2>
      <div className="container mx-auto py-12 flex flex-wrap">
        <div className="w-1/2 md:w-1/4 text-center px-2 py-2">
          <PresentationChartLineIcon className="h-12 w-12 mx-auto text-primary" />
          <p className="font-bold text-xl m-4">Save money</p>
          <p className="text-gray-700 leading-normal text-lg">
            Load more cargoes in your containers and increase effiency.
          </p>
        </div>
        <div className="w-1/2 md:w-1/4 text-center px-2 py-2">
          <ClockIcon className="h-12 w-12 mx-auto text-primary" />
          <p className="font-bold text-xl m-4">Save time</p>
          <p className="text-gray-700 leading-normal text-lg">
            Thousands of cargoes, multiple containers - it usually takes a few
            seconds to create your load plan
          </p>
        </div>
        <div className="w-1/2 md:w-1/4 text-center px-2 py-2">
          <BriefcaseIcon className="h-12 w-12 mx-auto text-primary" />
          <p className="font-bold text-xl m-4">Professional look</p>
          <p className="text-gray-700 leading-normal text-lg">
            With your logo attached it gives your customers and clients a
            professional impression
          </p>
        </div>
        <div className="w-1/2 md:w-1/4 text-center px-2 py-2">
          <ArchiveIcon className="h-12 w-12 mx-auto text-primary" />
          <p className="font-bold text-xl m-4">Documentation</p>
          <p className="text-gray-700 leading-normal text-lg">
            Everything is saved automatically - keep track of past shipments and
            load plans
          </p>
        </div>
      </div>
    </div>
  )
}

export default Benefits
