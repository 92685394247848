import React from "react"

import Testimonial from "./testimonial"
import { useStaticQuery, graphql } from "gatsby" // to query for image data

const T = props => {
  const image = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "profiles/joe.jpg" }) {
        base
        childImageSharp {
          gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
        }
      }
    }
  `)
  return (
    <Testimonial
      by="Joe O´Malley, ROCK-IT GLOBAL"
      text="Working on International Logistics for Touring within the Music Industry, Cargo-Planner has been an exceptional support to me in fine tuning the most suitable Chartered Aircraft when planning tour schedules and ensuring the correct equipment is utilised with precision, to stay competitive and on budget. The team have offered superb assistance through several challenging projects."
      image={image}
      {...props}
    ></Testimonial>
  )
}

export default T
